import React from 'react';

const BrandList = [
    {
        image: './images/brand/brande-01.png'
    },
    {
        image: './images/brand/brande-02.png'
    },
    {
        image: './images/brand/brande-03.png'
    },
    {
        image: './images/brand/brande-04.png'
    },
    {
        image: './images/brand/brande-05.png'
    },
]

const BrandFour = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="/#"><img src={`${data.image}`} alt="Clientes e Parceiros" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandFour;
