import React from "react";

const Radiocomunicacao = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-12 mt_md--20 mt_sm--20">
            <div className="content">
              <div className="section-title">
                <h4 className="sub-title text-left">
                  {" Em busca da melhor opção de rádio para o seu setor? "}
                  <br />
                  Você está no lugar certo, temos um amplo portfólio de rádios a
                  sua disposição
                </h4>
                <p className="b1 text-justify">
                  Radiocomunicação é o processo de enviar e receber informações por meio de ondas de rádio. É uma forma de comunicação sem fio amplamente utilizada em amplos segmentos do Setor Público (Municipais; Estaduais e Federais) e no Setor Privado (Agronegócio, Construtoras, Comércio, Energia, Indústrias, Hotéis e demais atividades).
                </p>

                <p className="b1 text-justify">
                  Os dispositivos de radiocomunicação estão disponíveis em diferentes tipos, cada um com seus recursos e capacidades exclusivas, conheça os mais utilizados, todos utilizando-se de frequências em UHF e/ou VHF.
                </p>

                <p className="b1 text-justify">
                  <strong>Rádios portáteis (HT) </strong>equipamentos de mão;
                </p>
                <p className="b1 text-justify">
                  <strong>Rádios móveis (ML)</strong>, equipamentos utilizados em veículos, tratores, caminhões, viaturas policiais, motos...
                </p>
                <p className="b1 text-justify">
                  <strong>Estações fixas (FX):</strong> São equipamentos móveis, alimentados com conversores e utilizando-se de antenas especificas, direcionando os sinais para as demais unidades.
                </p>
                <p className="b1 text-justify">
                  <strong>Estações Repetidoras (RPT):</strong> Um conjunto de equipamentos de rádio, controladora, conversores, antenas e duplexadores, instalados em pontos mais altos com o fim de ampliar o raio de comunicação para os demais periféricos (HT); (ML) e (FX).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Radiocomunicacao;
