import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiMapPin } from "react-icons/fi";
import { FaWhatsapp, FaMailBulk } from "react-icons/fa";

const ContactPage = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FaWhatsapp />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Telefone para Contato</h4>
                                        <p><a href="https://api.whatsapp.com/send?phone=556733893880&text=Ola%2C%20quero%20saber%20mais%20sobre%20a%20MrTelecomunicações" target="_blank" rel="noopener noreferrer">+55 (67) 3389-3880 - Ligação & WhatsApp</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FaMailBulk />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contato via e-mail</h4>
                                        <p><a href="mailto:recepcao@mrtele.com.br">recepcao@mrtele.com.br</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Nossa Localização</h4>
                                        <p><a href="https://maps.app.goo.gl/sN8Zcg5uUvdFsveM7" target="_blank" rel="noopener noreferrer"> R. José Luiz Pereira, 114 - Jardim Monte Líbano - 79004-140
                                            <br /> Campo Grande - MS, Brasil.</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--20">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div>
            </div>
        </>
    )
}
export default ContactPage;