import React from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/serviice-08.jpg',
        title: 'Radiocomunicação',
        description: 'Comunicação sem fronteiras: Potencialize sua equipe com nossos dispositivos de radiocomunicação de última geração',
        service: 'service-radiocomunicacao'
    },
    {
        image: '/images/service/serviice-03.jpg',
        title: 'Sinalização',
        description: 'Especialistas em comercialização e manutenção de sinalização para veículos especiais. Auxiliamos em sua missão, para sinalizar caminhos e salvar vidas',
        service: 'service-sinalizacao'
    },
    {
        image: '/images/service/serviice-05.jpg',
        title: 'Locação de Rádios',
        description: 'Nós alugamos soluções: o custo-benefício, a eficiência e conexão que seu negócio precisa, está aqui',
        service: 'service-locacao'
    },
    {
        image: '/images/service/serviice-04.jpg',
        title: 'Licença ANATEL',
        description: 'Parceria confiável: Nossos especialistas registrados no CREA-MS estão prontos para simplificar seu licenciamento na ANATEL',
        service: 'service-anatel'
    },
    {
        image: '/images/service/serviice-02.jpg',
        title: 'Assistência Técnica',
        description: 'Confiabilidade, Experiência e Garantia. Somos um  Serviço Autorizado Motorola (SAM), garantindo o melhor cuidado para seus equipamentos ',
        service: 'service-assistencia-tecnica'
    },
]
const ServiceTwo = ({ textAlign, cardStyle }) => {
    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to={`/${val.service}`}>
                                        <img src={`${val.image}`} alt="card Images" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <h4 className="title mb--20">
                                        <Link to={`/${val.service}`} dangerouslySetInnerHTML={{ __html: val.title }}></Link>
                                    </h4>
                                    <p className="description b1 text-justify color-gray mb--0" dangerouslySetInnerHTML={{ __html: val.description }}></p>
                                    <Link className="btn-default btn-border btn-small btn-border" to={`/${val.service}`}>Saiba mais</Link>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;