import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumTecnico from "../breadcrumb/BreadcrumTecnico";
import CalltoActionTecnico from "../calltoaction/CalltoActionTecnico";
import Separator from "../separator/Separator";
import AssistenciaTecnica from '../../pages/AssistenciaTecnica';

const ServiceTecnica = () => {
    return (
        <>
            <SEO title="Serviços Assistência Técnica || Mr Telecomunicações" />
            <Layout>
                <BreadcrumTecnico 
                    title="ASSISTÊNCIA TÉCNICA"
                />
                <div className="main-content">
                    <AssistenciaTecnica />

                    <Separator /> <br />
                    <CalltoActionTecnico /> <br />
                </div>
            </Layout>
        </>
    )
}
export default ServiceTecnica;