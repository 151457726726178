import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from "./../common/header/HeaderOne";
import FooterTwo from "./../common/footer/FooterTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BrandFour from '../elements/brand/BrandFour';
import Separator from "../elements/separator/Separator";
import HeaderTopNews from '../common/header/HeaderTopNews';
import AccordionOne from '../elements/accordion/AccordionOne';
import ServiceOne from '../elements/service/ServiceOne';
import Mission from '../elements/about/Mission';



const Service = () => {
    return (
        <>

            <SEO title="Mr Telecomunicações" />
            <main className="page-wrapper">
                
                <div className="header-relative">
                    <HeaderTopNews />
                    <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                </div>

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-2 variation-default height-500 bg_image" data-black-overlay="6" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-5-test.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    {/* <h3 className="subtitle">MR Telecomunicações</h3> */}
                                    <h1 className="title display-four">Soluções <span>Telecomunicações</span>.</h1>
                                    <div className="button-group">
                                        <a className="btn-default btn-border btn-small round btn-icon" target="_blank" href="/#">Produtos <i className="icon"><FiArrowRight /></i></a>
                                        <Link className="btn-default btn-border btn-small btn-border round btn-icon" to="#">Contato <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                <Mission />

                <Separator />

                {/* Start Accordion Area  */}
                <div className="rn-accordion-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Serviços"
                                    title = "Quais tipos de Serviços oferecemos?"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row mt--35 row--20">
                            <div className="col-lg-10 offset-lg-1">
                                <AccordionOne customStyle="rn-accordion-04" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Accordion Area  */}

                <Separator  />

                {/* Start Service Area  */}
                <div className="rwt-about-area rn-section-gap">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-lg-12 mt_md--40 mt_sm--40">
                                <div className="content">
                                    <div className="section-title">
                                        <h4 className="title">Serviços</h4>
                                        <h3 className="sub-title"> Radiocomunicação
                                        </h3>
                                        <p className="b1 text-justify">Nosso amplo portfólio, tem o equipamento que você precisa para maior controle e produtividade de suas atividades</p>

                                        <Separator /> <br />
                                        <h3 className="sub-title"> Assistência Técnica</h3>

                                        <p className="b1 text-justify">Em nossa sede dispomos de laboratório completo e técnicos certificados para avaliação e reparo de seus equipamentos. Somos a única SAM (Serviço Autorizado Motorola) do Estado de Mato Grosso do Sul.</p>

                                        <Separator /> <br />
                                        <h3 className="sub-title"> Sinalização</h3>

                                        <p className="b1 text-justify">Auxiliamos em sua missão, para sinalizar caminhos e salvar vidas. Somos especialistas em comercialização e manutenção de sinalização para veículos especiais.</p>

                                        <Separator /> <br />
                                        <h3 className="sub-title"> Licença ANATEL</h3>

                                        <p className="b1 text-justify">Contamos com profissionais experientes e devidamente registrados no CREA-MS, para auxiliá-lo em todo o processo de licenciamento junto a ANATEL.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
                {/* End Service Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Tecnologia Avançada para Conectar Você"
                                    title = "Nossos Serviços"
                                    description = "Simplificando a comunicação com soluções em telecomunicações que atendem às suas necessidades específicas."
                                    />
                            </div>
                        </div>
                        <ServiceOne
                            cardStyle = "card-style-1"
                            textAlign = "text-left"
                            />
                    </div>
                </div>
                {/* End Service Area  */}
                
                <Separator  />

                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Clientes & Marcas"
                                    title = "Entre Nossos Clientes e Marcas"
                                    description = "Representamos as melhores marcas do mercado, com produtos de qualidade e tecnologia de ponta."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandFour brandStyle="brand-style-2 variation-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                <FooterTwo />
            </main>
            
        </>
    )
}

export default Service;
