import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import Separator from "../separator/Separator";
import SectionTitleTwo from '../sectionTitle/SectionTitleTwo';
import CalltoActionHome from '../calltoaction/CalltoActionHome';
import TermosCondiçoes from '../../pages/TermosCondiçoes';
import ContactPage from '../contact/ContactPage';

const PageTermosCondiçoes = () => {
    return (
        <>
            <SEO title="Termos e Condições || Mr Telecomunicações" />
            <Layout>
                <BreadcrumbOne 
                    title="TERMOS E CONDIÇÕES"
                    rootUrl="/politica-de-privacidade"
                    parentUrl="Politica de Privacidade"
                    currentUrl="Uso e Serviço"
                />
                <div className="main-content">
                    {/* Start Elements Area  */}
                    <div className="rwt-elements-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <TermosCondiçoes teamStyle="" />
                                    
                                    <Separator /> <br />
                                    <CalltoActionHome />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    <Separator />

                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--10">
                                    <SectionTitleTwo
                                        textAlign = "text-center"
                                        title = "Contato"
                                    />
                                </div>
                            </div>
                            <ContactPage />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default PageTermosCondiçoes;