import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ProdutosOne from "./ProdutosOne";

const ProdutosFullWidth = () => {
    return (
        <>
            <SEO title="Produtos Full Width || Mr Telecomunicações - React Business  Template" />
            <Layout>
                <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Produtos Full Width"
                />
                <div className="main-content">
                    <div className="rwt-elements-area rn-section-gap">
                        <div className="container-fluid plr--30">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Produtos Full Width"
                                        title = "You can customize everything!"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ProdutosOne Column="col-lg-4 col-md-6 mt--30 produtos" />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default ProdutosFullWidth;
