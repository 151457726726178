import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbSinal from "../breadcrumb/BreadcrumbSinal";
import Separator from "../separator/Separator";
import Sinalização from "../../pages/Sinalização";
import CalltoActionSinalização from "../calltoaction/CalltoActionSinalização";
import SectionTitleTwo from '../sectionTitle/SectionTitleTwo';
import ContactSinal from '../contact/ContactSinal';


const ServiceSinalização = () => {
    return (
        <>
            <SEO title="Sinalização: Veiculos Especiais || Mr Telecomunicações" />
            <Layout>
                <BreadcrumbSinal 
                    title="SINALIZAÇÃO VISUAL E ACÚSTICA"
                    itemOne="Vendas"
                    itemTwo="Instalação"
                    itemThree="Assistência Técnica"
                />
                <div className="main-content">
                    {/* Start Elements Area  */}
                    <div className="rwt-elements-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Sinalização />

                                    <Separator /> <br />
                                    <div className="rwt-callto-action-area">
                                        <CalltoActionSinalização teamStyle="clltoaction-style-5" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    <Separator />
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--10">
                                    <SectionTitleTwo
                                        textAlign = "text-center"
                                        title = "Formulário de Contato"
                                    />
                                </div>
                            </div>
                            <ContactSinal />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default ServiceSinalização;