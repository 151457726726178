import React from "react";

const LicenciamentoAnatel = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-12 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                {/* <h3 className="title text-center">LICENÇA JUNTO A ANATEL</h3> */}
                <p className="b1 text-justify">
                  A Anatel (Agência Nacional de Telecomunicações) é a agência reguladora responsável por autorizar; homologar e fiscalizar o uso de radiofrequência no Brasil. A licença é obrigatória de acordo com a lei nº 9472 de 16/07/97, para qualquer pessoa física; jurídica ou órgão público que desejar instalar um sistema.
                </p>

                <p className="b1 text-justify">
                  Este projeto será efetuado por um engenheiro de telecomunicações, qualificados para executar o Projeto Técnico e prestar todo o Assessoramento até a obtenção das licenças.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenciamentoAnatel;
