/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';


const ProdutosDetailsContent = ({ data }) => {
    return (
        <div className="rwt-produtos-details rn-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="details-list">
                                <div className="thumbnail alignwide">
                                    <img className="radius w-70 " src={`${process.env.PUBLIC_URL}/${data.largeImage}`} alt="Home Image" />
                                </div>

                                <div className="row mt--40 row--30">
                                    <div className="col-lg-6">
                                        <div className="content-left">
                                            <h4 className="title">{data.title}</h4>
                                            <div className="single-list-wrapper">
                                                <div className="single-list">
                                                    <label>Marca:</label>
                                                    <span>{data.client}</span>
                                                </div>
                                                <div className="single-list">
                                                    <label>Tipo:</label>
                                                    <span>{data.type}</span>
                                                </div> <br/>
                                                <div className="description text-justify" dangerouslySetInnerHTML={{__html: data.body}}></div>
                                                
                                            </div>
                                            <div className="view-btn mt--50">
                                                <a className="btn-default btn-border btn-large round" href={`${data.btn.link}`}>{data.btn.buttontext}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                                        <div className="content-right">
                                            {/* <h5 className="subtitle text-center">{data.subtitle}</h5> */}
                                            <div className="title text-justify">
                                                    <p className="subtitle text-justify" dangerouslySetInnerHTML={{__html: data.subContent.title}}></p>
                                                    <p className="subtitle text-justify" dangerouslySetInnerHTML={{__html: data.subContent.subtext}}>
                                                    </p>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProdutosDetailsContent;
