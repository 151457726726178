import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">Home</Link>
            </li>
            
            <li><Link to="/a-mrtelecom">A MR</Link></li>

            <li className="has-droupdown"><Link to="#">Serviços</Link>
                <ul className="submenu">
                    <li><Link to="/service-radiocomunicacao">Radiocomunicação</Link></li>
                    <li><Link to="/service-sinalizacao">Sinalização</Link></li>
                    <li><Link to="/service-locacao">Locação de Rádios</Link></li>
                    <li><Link to="/service-anatel">Licenciamento Anatel</Link></li>
                    <li><Link to="/service-assistencia-tecnica">Assistência Técnica</Link></li>
                </ul>
            </li>

            <li className="has-droupdown"><Link to="/produtos">Produtos</Link>
                {/* <ul className="submenu">
                    <li><Link to="/radio-produtos">Radiocomunicação <span className="rn-badge-card">Hot</span></Link></li>
                    <li><Link to="/sinal-produtos">Sinalização <span className="rn-badge-card"> -15% OFF</span></Link></li>
                </ul>*/}
            </li>

            <li className="has-droupdown"><Link to="/blog-mrtelecom">Blog</Link>
            
            </li>

            <li className="has-droupdown"><Link to="/contato">Contato</Link></li>
        </ul>
    )
}
export default Nav;
