import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Home from "./pages/Home";
import Service from "./pages/Service";
import Produtos from "./pages/Produtos";

import CategoryList from "./pages/CategoryList";
import TagList from "./pages/TagList";
import Author from "./pages/Author";
import EmpresaMr from "./pages/EmpresaMr";

// Elements import Here 

import ServiceRadio from "./elements/service/ServiceRadio";
import ServiceAnatel from "./elements/service/ServiceAnatel";
import ServiceTecnica from "./elements/service/ServiceTecnica";
import ServiceSinalização from "./elements/service/ServiceSinalização";
import ServiceLocação from "./elements/service/ServiceLocação";

import PagePoliticaPrivacidade from "./elements/service/PagePoliticaPrivacidade";
import PageTermosCondiçoes from './elements/service/PageTermosCondiçoes';

import ProdutosFullWidth from "./elements/produtos/ProdutosFullWidth";
import ProdutosGrid from "./elements/produtos/ProdutosGrid";
import ProdutosBoxLayout from "./elements/produtos/ProdutosBoxLayout";
import ProdutosDetails from "./pages/ProdutosDetails";
import Contatos from "./elements/contact/Contact";


import BlogGridView from "./components/blog/BlogGridView";
import BlogMrTelecominucação from "./components/blog/BlogListView";
import BlogGridSidebar from "./components/blog/BlogGridSidebar";
import BlogListSidebar from "./components/blog/BlogListSidebar";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";


// Import Css Here 
import './assets/scss/style.scss';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>

                    {/* Blog Part  */}
                    <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} exact component={BlogGridView}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-mrtelecom"}`} exact component={BlogMrTelecominucação}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-grid-sidebar"}`} exact component={BlogGridSidebar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-list-sidebar"}`} exact component={BlogListSidebar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`} exact component={BlogDetails}/>
                    <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} exact component={CategoryList}/>
                    <Route path={`${process.env.PUBLIC_URL + "/archive/:slug"}`} exact component={Author}/>
                    <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} exact component={TagList}/>

                    <Route path={`${process.env.PUBLIC_URL + "/service"}`} exact component={Service}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service-radiocomunicacao"}`} exact component={ServiceRadio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service-anatel"}`} exact component={ServiceAnatel}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service-assistencia-tecnica"}`} exact component={ServiceTecnica}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service-sinalizacao"}`} exact component={ServiceSinalização}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service-locacao"}`} exact component={ServiceLocação}/>
                    
                    <Route path={`${process.env.PUBLIC_URL + "/politica-de-privacidade"}`} exact component={PagePoliticaPrivacidade}/>
                    <Route path={`${process.env.PUBLIC_URL + "/termos-e-condicoes"}`} exact component={PageTermosCondiçoes}/>

                    <Route path={`${process.env.PUBLIC_URL + "/contato"}`} exact component={Contatos}/>
                    <Route path={`${process.env.PUBLIC_URL + "/a-mrtelecom"}`} exact component={EmpresaMr}/>

                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>
                    
                    <Route path={`${process.env.PUBLIC_URL + "/produtos"}`} exact component={Produtos}/>

                    <Route path={`${process.env.PUBLIC_URL + "/produtos-full-width"}`} exact component={ProdutosFullWidth}/>
                    <Route path={`${process.env.PUBLIC_URL + "/produtos-grid-layout"}`} exact component={ProdutosGrid}/>
                    <Route path={`${process.env.PUBLIC_URL + "/produtos-box-layout"}`} exact component={ProdutosBoxLayout}/>
                    <Route path={`${process.env.PUBLIC_URL + "/produtos-details/:id"}`} exact component={ProdutosDetails}/>


                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
