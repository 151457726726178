import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from "react-icons/fa";

const AnyReactComponent = ({ text }) => (
  <div style={{ position: 'position', color: 'red', fontSize: '15px' }}>
    <FaMapMarkerAlt size={30} style={{ marginRight: '5px', color: 'red' }} />
    {text}
  </div>
);
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: -20.47759,
      lng: -54.61192
    },
    zoom: 15
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDasBf9GHyuvsEUYK7SzvedjwF3IZ1RMIg' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={-20.47759791633442}
            lng={-54.61190757427103}
            text="MR TELECOMUNICAÇÕES"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;