import React , {useState} from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Sua mensagem foi enviada com sucesso. Entrare-mos em contato muito em breve.</p>
    )
}

function ContactFormLocal({props , formStyle}) {
    const [ result,showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm(
            'service_p4x3hv8', 
            'template_jgfr42f', 
            e.target, 
            'user_jrfTH2e0Ely35ZCVFdT9S'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
      <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
          <div className="form-group">
              <input type="text" name="name" placeholder="Nome" required />
          </div>

          <div className="form-group">
              <input type="text" name="contact" placeholder="Contato" required />
          </div>

          <div className="form-group">
              <input type="email" name="email" placeholder="E-mail" required />
          </div>

          <div className="form-group">
              <input type="number" name="quantity" placeholder="Quantidade" required />
          </div>

          <div className="form-group">
              <input type="text" name="period" placeholder="Período" required />
          </div>

          <div className="form-group">
              <input type="text" name="purpose" placeholder="Finalidade" required />
          </div>

          <div className="form-group">
              <button className="btn-default btn-border btn-large">Enviar agora</button>
          </div>

          <div className="form-group">
              {result ? <Result /> : null}
          </div>
      </form>
  );
}
export default ContactFormLocal;
