import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Mission from '../elements/about/Mission';

import Separator from "../elements/separator/Separator";
import AboutMrTelecon from '../elements/about/AboutMrTelecon';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderTopBar from '../common/header/HeaderTopBar';
import ContactMrTelecom from '../elements/contact/ContactMrTelecom';

const EmpresaMr = () => {
    return (
        <>
            <SEO title="A Mr" />
            <main className="page-wrapper">
                
                <div className="header-transparent-with-topbar">
                    <HeaderTopBar />
                    <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                </div>

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-11 height-500 bg_image2" data-black-overlay="2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-21.png)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <h3 className="subtitle display-four">QUEM SOMOS </h3>
                                    <h2 className="title display-three">MR Telecomunicações</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}
                
                <Separator />
                <AboutMrTelecon />
                
                <Separator />
                <ContactMrTelecom />

                <Separator />
                <Mission />

                <FooterTwo />
            </main>
        </>
    )
}

export default EmpresaMr;
