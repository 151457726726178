import React from 'react';

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-12 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title text-justify-center">
                                <h3 className="title text-center"> Junte-se a nós e explore o futuro da tecnologia, garantindo soluções de comunicação de ponta para o seu negócio
                                </h3>
                                <p className="b1 text-justify">Há 30 anos, atuamos na <strong>área de telecomunicações</strong> no Estado de Mato Grosso do Sul, a única empresa de <strong>Serviço Autorizado Motorola – SAM</strong>, juntamente com a expertise do longo dos anos fomos nomeados Revenda Premium Platinum, permitindo e proporcionando uma experiência completa e segura em radiocomunicação.</p>

                                <p className="b1 text-justify">Do planejamento à execução, cobrimos todas as etapas, oferecendo <strong>projetos junto a Anatel</strong>, <strong>venda</strong>, <strong>locação</strong>, <strong>instalação</strong> e <strong>assistência técnica</strong> de alta qualidade.</p>

                                <h4 className="sub-title"> Faça-nos uma visita.
                                </h4>
                                
                                <a className="btn-default btn-border round text-center" href="https://api.whatsapp.com/send?phone=556733893880&text=Ola%2C%20quero%20saber%20mais%20sobre%20a%20MrTelecomunicações" target="_blank" rel="noopener noreferrer"><span>Whatsapp</span></a>

                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
