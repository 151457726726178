import React from "react";

const Locação = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-12 mt_md--20 mt_sm--20">
            <div className="content">
              <div className="section-title">
                <h4 className="sub-title text-left">
                  Locação de Rádios e Sistemas
                </h4>
                
                <p className="b1 text-justify">
                  O aluguel de radiocomunicadores é uma solução prática e eficiente para empresas, independentemente de seu porte, que precisam manter uma comunicação constante e segura em suas operações.
                </p>

                <p className="b1 text-justify">
                  São inúmeros os benefícios da locação, primeiramente, essa alternativa possibilita:
                </p>

                <ul className="split-list">
                  <li>Minimizar o investimento inicial. Não é necessário descapitalizar sua empresa. Com um baixo investimento inicial, sua empresa já diminui custos através do ganho em produtividade;</li>
                  <li>Não tem custo de manutenção, reduzindo preocupações e custos internos inesperados;</li>
                  <li>Você pode aumentar ou diminuir o parque de equipamentos conforme sua necessidade; </li>
                  <li>Nossa planta de rádio é nova e constantemente revisada em nosso laboratório SAM-Motorola, proporcionando maior segurança, confiabilidade e qualidade nos serviços prestados. </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Locação;
