import React from 'react';

const BreadcrumbOne = ({ title, rootUrl, parentUrl, currentUrl }) => {
    return (
        <div className="breadcrumb-area breadcarumb-style-1 ptb--85">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner text-center">
                            <h2 className="title-color" dangerouslySetInnerHTML={{ __html: title }}></h2>
                            {/* <ul className="page-list">
                                <li className="rn-breadcrumb-item">Rádios</li>
                                <li className="rn-breadcrumb-item">Sistemas</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default BreadcrumbOne;