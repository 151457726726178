import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbAnatel from "../breadcrumb/BreadcrumbAnatel";
import CalltoActionAnatel from "../calltoaction/CalltoActionAnatel";
import Separator from "../separator/Separator";
import LicenciamentoAnatel from '../../pages/LicenciamentoAnatel';

const ServiceAnatel = () => {
    return (
        <>
            <SEO title="Serviços Licenciamento Anatel || Mr Telecomunicações" />
            <Layout>
                <BreadcrumbAnatel 
                    title="LICENÇA JUNTO A ANATEL"
                    rootUrl="/service"
                    parentUrl="Service"
                    currentUrl="Licenciamento Anatel"
                />
                <div className="main-content">
                    {/* Start Elements Area  */}
                    <div className="rwt-elements-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <LicenciamentoAnatel teamStyle="" />
                                    
                                    <Separator /> <br />
                                    <CalltoActionAnatel />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    <Separator />
                </div>
            </Layout>
        </>
    )
}
export default ServiceAnatel;