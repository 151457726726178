import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import Separator from "../separator/Separator";
import PoliticaPrivacidade from '../../pages/PoliticaPrivacidade';
import SectionTitleTwo from '../sectionTitle/SectionTitleTwo';
import CalltoActionHome from '../calltoaction/CalltoActionHome';
import ContactPage from '../contact/ContactPage';

const PagePoliticaPrivacidade = () => {
    return (
        <>
            <SEO title="Politica de Privacidade || Mr Telecomunicações" />
            <Layout>
                <BreadcrumbOne 
                    title="POLÍTICA DE PRIVACIDADE"
                    rootUrl="/termos-e-condicoes"
                    parentUrl="Termos e Condições"
                    currentUrl="Segurança da Informação"
                />
                <div className="main-content">
                    {/* Start Elements Area  */}
                    <div className="rwt-elements-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <PoliticaPrivacidade teamStyle="" />
                                    
                                    <Separator /> <br />
                                    <CalltoActionHome />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    <Separator />

                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--10">
                                    <SectionTitleTwo
                                        textAlign = "text-center"
                                        title = "Contato"
                                    />
                                </div>
                            </div>
                            <ContactPage />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default PagePoliticaPrivacidade;