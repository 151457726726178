import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Radiocomunicação
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Nosso amplo portfólio, tem o equipamento que você precisa para maior controle e produtividade de suas atividades.</Card.Body>
                </Accordion.Collapse>
            </Card>


            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Sinalização
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Especialistas em comercialização e manutenção de sinalização para veículos especiais. Auxiliamos em sua missão, para sinalizar caminhos e salvar vidas</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Locação de Rádios
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Nós alugamos soluções: o custo-benefício, a eficiência e conexão que seu negócio precisa, está aqui.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Licença ANATEL
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Contamos com profissionais experientes e devidamente registrados no CREA-MS, para auxiliá-lo em todo o processo de licenciamento junto a ANATEL.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Assistência Técnica
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>Em nossa sede dispomos de laboratório completo e técnicos certificados para avaliação ereparo de seus equipamentos. Somos a única SAM (Serviço Autorizado Motorola) do Estado de Mato Grosso do Sul.</Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
export default AccordionOne
