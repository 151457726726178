/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

const Darkmode = () => {
    const lightTheme = 'light';
    const darkTheme = 'dark';
    
    // We use the useEffect hook to set the theme to the user's choice or to the default theme when the component is mounted
    useEffect(() => {
        const currentTheme = window.localStorage.getItem('theme') ?? lightTheme;
        document.body.classList.add(currentTheme);
    }, [lightTheme]);

    const switchTheme = (e) => {
        if (document.body.classList.contains(lightTheme)) {
            document.body.classList.replace(lightTheme, darkTheme);
            window.localStorage.setItem('theme', darkTheme);
        } else {
            document.body.classList.replace(darkTheme, lightTheme);
            window.localStorage.setItem('theme', lightTheme);
        }
    };

    return null;
};

export default Darkmode;
