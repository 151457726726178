/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FaWhatsapp } from "react-icons/fa";

const callToActionData = {
    title: "Mas fique tranquilo",
    subtitle: "Você pode contar com a MR Telecomunicações para realizar todos os trâmites necessários na aquisição de sua licença.",
    btnText: "Clique aqui: WhatsApp",
}

const CalltoActionVeiculos = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="title">{callToActionData.title}</h4>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h6 className="subtitle">{callToActionData.subtitle}</h6>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="call-to-btn text-center">
                                        <a className="btn-default btn-border btn-icon" href="https://api.whatsapp.com/send?phone=556733893880&text=Ola%2C%20quero%20saber%20mais%20sobre%20a%20MrTelecomunicações" target="_blank" rel="noopener noreferrer">{callToActionData.btnText} <i className="icon"><FaWhatsapp /></i></a>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionVeiculos;