import React from 'react';
import ProdutosDetailsContent from "../elements/produtos/ProdutosDetailsContent";
import ProdutosData from "../data/produtos/ProdutosData.json";
import SEO from "../common/SEO";
import Layout from "../common/Layout";


const ProdutosDetails = ({match: {params: {id}}}) => {
    const produtosId = parseInt(id, 10)
    const data = ProdutosData.filter(produtos => produtos.id === produtosId);
    return (
        <>
            <SEO title="Produtos Detalhes || MR Telecomunicações" />
            <Layout>
                <ProdutosDetailsContent data={data[0]} />
            </Layout>
        </>
    )
}

export default ProdutosDetails;