import React from 'react';
import SEO from "../../common/SEO";
import ServiceTwo from '../service/ServiceTwo';
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactPage from "./ContactPage";
import Separator from "../separator/Separator";
import HeaderOne from "../../common/header/HeaderOne";
import HeaderTopBar from '../../common/header/HeaderTopBar';
import FooterTwo from '../../common/footer/FooterTwo';
import Copyright from '../../common/footer/Copyright';

const Contact = () => {
    return (
        <>
            <SEO title="Contato || Mr Telecomunicações" />

                <div className="header-relative">
                    <HeaderTopBar />
                    <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                </div>

                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Formulário de Contato"
                                        title = "Quer saber mais sobre os nossos produtos ou serviços?"
                                        description = "Entre em contato nos ligando, vindo até nosso endereço ou envie uma mensagem via e-mail e entraremos em contato o mais breve possível."
                                    />
                                </div>
                            </div>
                            <ContactPage />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                    <Separator />
                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        subtitle = "Potencialize sua comunicação!"
                                        title = "Nossos Serviços."
                                        />
                                </div>
                            </div>
                            <ServiceTwo 
                                cardStyle = "card-style-1"
                                textAlign = "text-left"
                                />
                        </div>
                    </div>
                {/* End Service Area  */}

                <FooterTwo />
                <Copyright />


                </div>
        </>
    )
}
export default Contact;