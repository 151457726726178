import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbRadio from "../breadcrumb/BreadcrumbRadio";
import Separator from "../separator/Separator";
import Radiocomunicacao from '../../pages/Radiocomunicação';
import CalltoActionFive from '../calltoaction/CalltoActionFive';
// import ContactRadio from '../contact/ContactRadio';
// import SectionTitleTwo from '../sectionTitle/SectionTitleTwo';
// import BlogClassicData from '../../data/blog/BlogList.json';
// import BlogList from '../../components/blog/itemProp/BlogList';
// import SectionTitle from '../sectionTitle/SectionTitle';
// var BlogListData = BlogClassicData.slice(0, 3);

const ServiceRadio = () => {
    return (
        <>
            <SEO title="Serviços Radiocomunicação || Mr Telecomunicações" />
            <Layout>
                <BreadcrumbRadio
                    title="RADIOCOMUNICAÇÃO"
                />

                <div className="main-content">
                    {/* Start Elements Area  */}
                    <div className="rwt-elements-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Radiocomunicacao teamStyle="" />

                                    <Separator /> <br />
                                    <CalltoActionFive />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    <Separator />

                    {/* Start Contact Area  */}
                    {/* <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--10">
                                    <SectionTitleTwo
                                        textAlign="text-center"
                                        title="Formulário de Contato"
                                    />
                                </div>
                            </div>
                            <ContactRadio />
                        </div>
                    </div> */}

                </div>
            </Layout>
        </>
    )
}
export default ServiceRadio;