import React from "react";
import Separator from "../elements/separator/Separator";

const Sinalização = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-12 mt_md--10 mt_sm--10">
            <div className="content">
              <div className="section-title">
                <Separator />
                {/* <h3 className="sub-title text-center"> Sinalização</h3> */}
                <p className="b1 text-justify">
                  A <strong>MR</strong> desde 2000, vem atuando no mercado de Sinalização Visual; Acústica e Transformações de Veículos especiais voltados a Segurança Pública e Saúde, no âmbito Municipal; Estadual e Federal, além do atendimento as empresas privadas de segurança; unidades de atendimento móvel da rede particular e empresa de serviços.
                </p>
                <p className="b1 text-justify">
                  Desde a comercialização, a qual é projetada de acordo com o modelo do veículo e atividade fim a que se destina, a <strong>MR</strong> teve como principal meta se especializar no atendimento técnico, pois desempenha um papel vital nesse processo. Equipes dedicadas de profissionais altamente treinados entendem a importância da sinalização em situações críticas. Cada componente da sinalização, desde barras intermitentes; estrobos e sirenes distintivas, são instalados com precisão para garantir visibilidade máxima e alertar outros usuários da estrada sobre a presença e ação iminente desses veículos.  Cada detalhe é verificado e ajustado para garantir que, quando a necessidade surgir, esses veículos estejam na vanguarda da resposta.
                </p>
                <Separator />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sinalização;
