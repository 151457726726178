/* eslint-disable no-unused-vars */
import { useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";


const HeaderOne = ({ btnStyle, HeaderSTyle }) => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader(50);
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`
    const { clientHeight } = ref;

    const checkChange = (value) => {
        setCheck(value);
    };

    return (
        <>
            <header ref={ref} className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}>
                <div className="container position-relative">
                    <div className="row align-items-center row--0">
                        <div className="col-lg-2 col-md-4 col-3">
                            <Logo
                                image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                            />
                        </div>
                        <div className="col-lg-8 col-md-4 col-6 position-static">
                            <div className="header-center">
                                <nav className="mainmenu-nav d-none d-lg-block">
                                    <Nav />
                                </nav>
                                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div className="hamberger">
                                        <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                    </div>
                                </div>
                                <div className="header-btn">
                                    <a className={`btn-default btn-border ${btnStyle}`} href="https://api.whatsapp.com/send?phone=556733893880&text=Ola%2C%20quero%20saber%20mais%20sobre%20a%20MrTelecomunicações" target="_blank" rel="noopener noreferrer">Fale Conosco</a>
                                </div>
                                {/* Ativação dos modos Dark/Light */}
                                <Darkmode />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-3">
                            <Logo
                                image={`${process.env.PUBLIC_URL}/images/logo/logo-motorola.png`}
                                image2={`${process.env.PUBLIC_URL}/images/logo/logo-motorola.png`}
                            />
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
        </>
    )
}
export default HeaderOne;