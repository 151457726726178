import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumLocação from "../breadcrumb/BreadcrumLocação";
import CalltoActionLocação from "../calltoaction/CalltoActionLocação";
import Separator from "../separator/Separator";
import Locação from '../../pages/Locação';
import SectionTitleTwo from '../sectionTitle/SectionTitleTwo';
import ContactLocal from '../contact/ContactLocal';

const ServiceLocação = () => {
    return (
        <>
            <SEO title="Serviços Locação de Rádios Comunicadores || Mr Telecomunicações" />
            <Layout>
                <BreadcrumLocação
                    title="LOCAÇÃO DE RADIOS "
                />
                <div className="main-content">
                    <Locação />

                    <Separator /> <br />
                    <CalltoActionLocação />
                </div>
                <Separator />
                {/* Start Contact Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--10">
                                <SectionTitleTwo
                                    textAlign="text-center"
                                    title="Formulário de Contato"
                                />
                            </div>
                        </div>
                        <ContactLocal />
                    </div>
                </div>
                {/* End Contact Area  */}
            </Layout>
        </>
    )
}
export default ServiceLocação;