/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FaWhatsapp } from "react-icons/fa";

const callToActionData = {
    title: "Conte conosco para manter sua comunicação no mais alto nível.",
    subtitle: "Quando se trata de assistência técnica de alta qualidade, confie na MR Telecomunicações, a líder no mercado de Mato Grosso do Sul.",
    btnText: "Clique aqui: WhatsApp",
}

const CalltoActionTecnico = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="title">{callToActionData.title}</h4>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="call-to-btn text-center">
                                        <a className="btn-default btn-border btn-icon" href="https://api.whatsapp.com/send?phone=556733893880&text=Ola%2C%20quero%20saber%20mais%20sobre%20a%20MrTelecomunicações" target="_blank" rel="noopener noreferrer">{callToActionData.btnText} <i className="icon"><FaWhatsapp /></i></a>
                                        <br />
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionTecnico;