import React from 'react';
import SEO from "../common/SEO";
// import {Link} from "react-router-dom";
// import { FaBroadcastTower, FaSatelliteDish} from "react-icons/fa";
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import ServiceTwo from '../elements/service/ServiceTwo';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ContactPage from "../elements/contact/ContactPage";
import TimelineHome from "../elements/timeline/TimelineHome";

import Separator from "../elements/separator/Separator";
import CalltoActionHome from '../elements/calltoaction/CalltoActionHome';
import SectionTitleTwo from '../elements/sectionTitle/SectionTitleTwo';
import SectionTitleProdutos from '../elements/sectionTitle/SectionTitleProdutos';

const Home = () => {
    return (
        <>
            <SEO title="Home" />
            <main className="page-wrapper">
                
                <div className="header-transparent-with-topbar">
                    <HeaderTopBar />
                    <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                </div>

                {/* Start Slider area  */}
                <div className="slider-area slider-style-10 height-750 bg_image" data-black-overlay="6" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-6.jpg)`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 pb-5 mb-4 ml-4">
                                    <div className="inner text-center">
                                        <h1 className="title display-three">MR Telecomunicações</h1>
                                        <ul className="list-icon ml-4">
                                        <li> Nosso propósito é manter você conectado, seguro e sempre um passo à frente</li>
                                        <li> Soluções de Telecomunicações para empresas de todos os portes e segmentos do Brasil </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                {/* End Slider area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    title = "Serviços"
                                    description = "Experiência e Compromisso ao Seu Dispor: Transformando Necessidades em Resultados"
                                    />
                            </div>
                        </div>
                        <ServiceTwo 
                            cardStyle = "card-style-1"
                            textAlign = "text-center"
                            
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitleProdutos
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Destaques"
                                        title = "Produtos"
                                    />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineHome />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator /> <br />
                    
                    <CalltoActionHome /> <br />
                
                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--20">
                                <SectionTitleTwo
                                    textAlign = "text-center"
                                    title = "Quer saber mais sobre os nossos produtos ou serviços?"
                                />
                            </div>
                        </div>
                        <ContactPage />
                    </div>
                </div>
                {/* End Elements Area  */}

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Home
