import React from "react";
import Separator from "../elements/separator/Separator";

const AssistenciaTecnica = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-12 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <p className="b1 text-justify">
                  Dispositivos de comunicação são ferramentas de uso essenciais e indispensáveis, assim a <strong>MR</strong> investiu em laboratório moderno e equipado com diversos instrumentais; técnicos com treinamentos de capacitação e reciclagem junto a Motorola Solution, bem como nos fabricantes de periféricos para os sistemas de UHF e VHF. Somos por anos consecutivos a única empresa Serviço Motorola (SAM) em Mato Grosso do Sul.
                </p>
                <p className="b1 text-justify">
                  Quando você busca uma assistência técnica especializada em radiocomunicação, desfruta de vários benefícios, incluindo;
                </p>
                <ul className="split-list">
                  <li>Diagnósticos rápidos e assertivos</li>
                  <li>Aumento da vida útil do dispositivo</li>
                  <li>Melhor desempenho e redução do tempo de inatividade</li>
                  <li>Além disso, os provedores de assistência técnica especializada
                  possuem as habilidades, ferramentas e conhecimentos
                  necessários para diagnosticar e corrigir problemas técnicos
                  complexos.</li>
                </ul>

                <h3 className="sub-title text-left"> Oferecemos diversas opções de serviços, adequados à realidade de cada cliente:</h3>
                <ul className="split-list">
                  <li>Contratos de Manutenções Preventivas;</li>
                  <li>Contratos de Manutenções Corretivas;</li>
                  <li>Atendimentos Técnicos in loco;</li>
                  <li>Serviços de manutenção, remanejamento e instalação incluídos.</li>
                  <li>Programação e Reprogramação.</li>
                </ul>
                <Separator />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssistenciaTecnica;
