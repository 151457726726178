import React from "react";
import Separator from "../elements/separator/Separator";

const PoliticaPrivacidade = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-12">
            <div className="content">
              <div className="section-title">
                <h5 className="sub-title text-justify">
                  {
                    " Esta política de Termos de Uso é válida a partir de Outubro - 2023."
                  }
                </h5>
                <h3 className="sub-title text-justify">
                  POLÍTICA DE PRIVACIDADE
                </h3>
                <p className="b1 text-justify">
                    MR TELECOMUNICACOES E SERVICOS LTDA <br />
                    Seus dados pessoais (“dados”) são bens valiosos que devem ser preservados, e nossa missão é garantir a sua segurança e confidencialidade. Acreditamos que a transparência é fundamental para a confiança que você deposita em nós, e é por isso que criamos esta Política de Privacidade – clara, direta e alinhada com nossos valores e serviços.
                    <br />
                    Nós, da MR TELECOMUNICACOES E SERVICOS LTDA (pode nos chamar de MR TELECOMUNICACOES), somos especializados no comércio varejista de equipamentos de telefonia e comunicação, oferecendo desde os dispositivos mais clássicos até os mais modernos. Além disso, prestamos serviços relacionados à instalação, manutenção e reparação de acessórios para veículos automotores, capotaria e comércio varejista de peças e acessórios novos para tais veículos. Para a prestação destes serviços, lidamos com diferentes informações, e garantimos total comprometimento em proteger cada dado confiado a nós.
                    <br />
                    É essencial que você entenda e esteja confortável com nossa Política de Privacidade. Por isso, incentivamos que leia atentamente e compreenda como e por que coletamos, usamos e armazenamos suas informações.
                    <br />

                    Lembramos que:
                  <ul>
                    <li>MR TELECOMUNICACOES: Somos uma empresa especializada no comércio varejista de equipamentos de telefonia e comunicação, bem como em diversos serviços relacionados a veículos e representações comerciais.</li>
                    <li>MR TELECOMUNICACOES destaca-se pela sua expertise no setor de telecomunicações, com um portfólio diversificado que abrange desde a venda de equipamentos modernos até a manutenção e reparo de diversos dispositivos.</li>
                    <li>Atuamos em estreita colaboração com diversos parceiros e fornecedores para garantir a excelência em todos os nossos serviços.</li>
                    <li>Site: Refere-se à plataforma digital da MR TELECOMUNICACOES E SERVICOS LTDA, onde oferecemos informações sobre nossos produtos, serviços e outras atividades da empresa.</li>
                    <li>USUÁRIO: Você, que acessa ou interage com as atividades e serviços oferecidos pelo nosso site.</li>
                  </ul>
                  <Separator />
                  <p>
                    Mr Telecomunicacoes e Servicos LTDA: Pessoa jurídica de direito privado inscrita no CNPJ sob o no. 01.065.749/0001-80, com sede na Rua Jose Luiz Pereira, na rua 114, Campo Grande/MS - 79004-140.
                  </p>
                </p>

                <h3 className="sub-title text-justify">Informações pessoais que coletamos</h3>

                <p className="b1 text-justify">
                  • As informações listadas a seguir serão coletadas, processadas, armazenadas e tratadas pela MR TELECOMUNICACOES. Estas informações ficarão disponíveis para consulta interna, sempre que pertinente, em situações permitidas ou necessárias, ou ainda, mediante requisição do titular dos dados pessoais, conforme exigências legais.
                  <br />
                  • Se você é nosso cliente ou usuário, seja porque adquiriu produtos ou porque contratou ou está no processo de contratação dos nossos serviços, podemos coletar as seguintes informações para assegurar a melhor experiência e atendimento:<br />
                  <ul>
                      <li>CPF;</li>
                      <li>RG;</li>
                      <li>Data de Nascimento;</li>
                      <li>E-mail;</li>
                      <li>Número do telefone celular;</li>
                      <li>Comprovante de endereço;</li>
                      <li>Informações relacionadas à sua preferência de produtos ou serviços;</li>
                      <li>Feedbacks sobre os produtos ou serviços adquiridos;</li>
                      <li>Histórico de compras ou serviços contratados.</li>
                  </ul>
                </p>

                <h3 className="sub-title text-justify">
                  Para qual finalidade utilizamos dados
                </h3>
                <p className="b1 text-justify">
                  • Coletamos informações com o objetivo de proporcionar o melhor serviço e suporte aos nossos clientes, sempre de acordo com a Lei Geral de Proteção de Dados Pessoais (LGPD) e outras regulamentações relevantes.
                  <br />

                  • Identificar e autenticar os USUÁRIOS adequadamente.
                  <br />

                  • Administrar, prestar os serviços e cumprir com as obrigações resultantes da contratação de nossos serviços.
                  <br />

                  • Atender de forma eficaz todas as solicitações e dúvidas dos usuários.
                  <br />

                  • Manter os registros atualizados para contatos via telefone, e-mail, SMS ou outros meios de comunicação.
                  <br />

                  • Melhorar a experiência de uso do site.
                  <br />

                  • Conduzir estatísticas, pesquisas e estudos sobre o comportamento dos USUÁRIOS no site, garantindo a anonimização dos dados.
                  <br />

                  • Informar sobre novidades, produtos, serviços e outros conteúdos relevantes.
                  <br />

                  • Garantir direitos e deveres associados ao uso do site.
                  <br />

                  • Atender a ordens judiciais ou solicitações de autoridades administrativas.
                  <br />

                  • Compartilhar informações com parceiros para manutenção e otimização do site.
                  <br />

                  • Compartilhar dados com parceiros com o objetivo de enriquecer bases de dados e evitar fraudes.
                  <br />

                  • Trocar informações com instituições financeiras para a prestação adequada de serviços.
                  <br />

                  • Assegurar a segurança das operações, compartilhando dados com terceiros responsáveis pela validação ou autenticação de informações, quando necessário.
                  <br />

                  • Utilizar dados pessoais para enviar comunicados, ofertas e outros informativos relevantes.
                  <br />

                  • Fornecer relatórios e métricas relevantes a parceiros e representantes, garantindo a anonimização dos dados quando necessário.
                  <br />

                  • Adotar medidas de segurança para proteger dados pessoais contra acessos não autorizados ou situações ilícitas.
                  <br />

                  Garantida a finalidade, haverá também anonimização dos dados dos titulares.

                  Possuímos estipulações contratuais e cobramos de nossos parceiros a adoção de medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.

                  • Utilizar dados exclusivamente para a prestação dos serviços contratados, sem venda ou uso indevido.
                  <br />

                  • Os dados pessoais coletados serão tratados apenas enquanto houver finalidade que justifique este tratamento, de forma que, para utilizá-los para quaisquer outras atividades não citadas nesta política, solicitaremos sempre o seu consentimento.
                  <br />

                  • Garantir que os dados coletados serão tratados apenas enquanto houver justificativa para tal, solicitando sempre o consentimento do usuário para qualquer uso adicional.
                </p>
                <h3 className="sub-title text-justify">
                  Confira os seus direitos
                </h3>
                <p className="b1 text-justify">
                  • Correção: Permite que você solicite a correção dos seus Dados Pessoais caso estejam incompletos, inexatos ou desatualizados.
                  <br />

                  • Confirmação de uso: Em qualquer momento, você pode verificar se a MR TELECOMUNICACOES trata algum dado pessoal seu ou de quem você representa. Caso afirmativo, pode solicitar uma cópia dos dados pessoais sob nossa guarda.
                  <br />

                  • Anonimização: Você pode solicitar a anonimização dos seus dados, garantindo que estes não possam mais ser associados a você, deixando de ser considerados dados pessoais.
                  <br />

                  • Bloqueio: Possibilita que você pause, temporariamente, nossa capacidade de tratar seus dados, desde que não exista uma razão legal que nos permita ou obrigue a continuar tratando a informação.
                  <br />

                  • Exclusão: Se desejar, podemos excluir todos os seus dados pessoais de forma permanente, a menos que exista uma razão legal ou processos em andamento que impeçam essa exclusão.
                  <br />

                  • Portabilidade: Você tem o direito de solicitar a transferência dos seus dados pessoais entre a MR TELECOMUNICACOES e você, ou terceiros de sua escolha. Essa transferência será realizada de forma estruturada e compatível, respeitando direitos de propriedade intelectual e confidencialidade. Similarmente, você pode pedir que outras empresas nos forneçam seus Dados Pessoais para simplificar nossa relação.
                  <br />

                  • Informações: Pode solicitar informações completas e transparentes sobre com quais entidades a MR TELECOMUNICACOES compartilha seus dados pessoais.
                  <br />

                  • Revogação do consentimento: Você tem o direito de retirar seu consentimento em relação aos tratamentos de dados que fazemos. Isso afetará apenas os tratamentos realizados a partir da revogação e não os que foram feitos anteriormente.


                </p>

                <h3 className="sub-title text-justify">Como utilizamos as informações pessoais</h3>
                <p className="b1 text-justify">
                  • Utilizamos seus dados pessoais para oferecer os melhores serviços, comunicar-nos com você, personalizar sua experiência, cumprir obrigações legais e assegurar sua proteção.
                  <br />

                  • Saiba que a MR TELECOMUNICACOES pode enriquecer sua base de dados, adicionando informações oriundas de outras fontes legítimas. Ao concordar com os termos desta Política de Privacidade, você manifesta seu consentimento expresso.
                  <br />

                  <ul>
                    <li>Em nossa colaboração com parceiros e/ou representantes do mercado, poderemos compartilhar dados pessoais conforme necessário, respeitando os objetivos desta política e obrigações legais. Isso inclui situações como:</li>
                    <ul>
                        <li>Processos judiciais, em andamento ou potenciais.</li>
                        <li>Exercício, defesa ou estabelecimento de nossos direitos ou de terceiros.</li>
                        <li>Atendimento a ordens de autoridades competentes.</li>
                    </ul>
                    <li>A MR TELECOMUNICACOES pode registrar todas as interações efetuadas pelos USUÁRIOS em nosso site, por meio de registros (logs), que incluem:</li>
                    <ul>
                        <li>Endereço IP e Portal Lógica de Origem.</li>
                        <li>Ações realizadas no site.</li>
                        <li>Telas acessadas.</li>
                        <li>Datas, horários e duração das atividades e acessos.</li>
                        <li>Informações sobre o dispositivo utilizado, como sistema operacional, geolocalização e, se necessário, outros aplicativos instalados.</li>
                        <li>Session ID.</li>
                        <li>Histórico de transações e contratações realizadas.</li>
                        <li>Padrões e comportamentos observados durante a interação com nosso site.</li>
                    </ul>
                  </ul>
                  • Podemos empregar outras tecnologias para coletar dados de navegação dos USUÁRIOS, sempre em conformidade com esta política e respeitando suas preferências sobre coleta, uso e armazenamento.
                  <br />

                  • Outras tecnologias poderão ser utilizadas para a obtenção de dados de navegação dos USUÁRIOS, sempre respeitando os termos desta política e as opções destes a respeito de sua coleta, de seu uso e de seu armazenamento.
                </p>

                <h3 className="sub-title text-justify">
                  Segurança da informação
                </h3>
                <p className="b1 text-justify">
                  • A MR TELECOMUNICACOES e seus Usuários comprometem-se com a veracidade das informações fornecidas e serão responsabilizados em casos de ilegalidades cometidas por meio de informações falsas ou não autorizadas.
                  <br />

                  • Faremos todos os esforços possíveis para proteger os dados e informações pessoais contra perda, roubo ou qualquer forma de uso inadequado, assim como contra acesso não autorizado, divulgação, alteração e destruição.
                  <br />

                  • É expressamente proibido para nossos colaboradores e terceirizados reproduzir ou compartilhar qualquer conteúdo sob nossa posse sem a devida autorização.
                  <br />

                  • Quando necessário, todas as informações ou dados pessoais inseridos digitalmente ou convertidos de meio físico para o virtual serão criptografados por nós.
                  <br />

                  • A MR TELECOMUNICACOES não assume responsabilidade por qualquer vazamento ou mau tratamento de dados que não seja de sua responsabilidade direta ou indireta.
                  <br />

                  • Garantimos que apenas os colaboradores ou terceiros essenciais para lidar com dados específicos receberão as devidas credenciais para tais funções. Aqueles com acesso aos dados pessoais ou informações consideradas confidenciais serão responsáveis por sua proteção e pelo uso adequado de suas credenciais.
                  <br />

                  • Os dados pessoais e outras informações relacionadas aos objetivos desta política não serão mantidos por mais tempo do que o estritamente necessário, a menos que estejam devidamente anonimizados, de acordo com os Artigos 12 e 16 da LGPD. Em certos casos, como obrigações legais, prevenção de fraudes ou exercício do direito de defesa, os dados podem ser retidos por períodos mais longos.
                </p>

                <h3 className="sub-title text-justify">
                  Sobre a política de privacidade
                </h3>
                <p className="b1 text-justify">
                  • A MR TELECOMUNICACOES está constantemente buscando aperfeiçoar seu sistema para garantir serviços e proteções de alta qualidade. Em decorrência disso, esta Política de Privacidade pode ser atualizada ou alterada por nós. Sempre buscamos melhorar nossa Política e quaisquer alterações serão refletidas nesta página. Assim, recomendamos que sempre confira esta seção antes de usar um de nossos serviços. Ao publicarmos quaisquer alterações na Política, atualizaremos a data de "última modificação". 
                  <br /> 

                  Importante ressaltar: Ao continuar a acessar ou usar os Serviços após tais mudanças entrarem em vigor, você concorda em estar vinculado à versão revisada da Política.
                  <br />

                  • Quaisquer modificações nesta Política entrarão em vigor após 01 (um) dia da sua comunicação.
                </p>

                <h3 className="sub-title text-justify">
                  Resolução de conflitos
                </h3>
                <p className="b1 text-justify">
                  • Ambas as partes escolhem o foro da Comarca de Campo Grande/MS, renunciando expressamente a qualquer outro, independentemente de quão privilegiado possa ser, e aderindo aos regulamentos estabelecidos no Código Civil brasileiro e demais leis do país.
                  <br />

                  • Solicitações judiciais para registros e informações serão processadas de acordo com a Lei nº 13.709/2018.
                </p>

                <h3 className="sub-title text-justify">
                  Vigência da Política de Privacidade
                </h3>
                <p className="b1 text-justify">
                  • Esta Política de Privacidade entra em vigor assim que for publicada no site da MR TELECOMUNICACOES.
                </p>

                <h3 className="sub-title text-justify">Lei Geral de Proteção de Dados Pessoais (LGPD)</h3>
                <p className="b1 text-justify">
                  • A LGPD é a legislação que define as regras para o tratamento de dados pessoais, inclusive em ambientes digitais, seja por indivíduos ou por entidades de direito público ou privado. Seu principal objetivo é proteger os direitos fundamentais de liberdade, privacidade e o desenvolvimento integral da personalidade da pessoa natural.
                  <br />

                  • O tratamento de dados pessoais realizado pela MR TELECOMUNICACOES está fundamentado em necessidades e finalidades específicas, conforme delineado anteriormente, alinhado aos princípios de livre acesso, segurança, transparência e todos os demais princípios estipulados na lei. Afinal, valorizamos profundamente nossos clientes.
                  <br />

                  • Todo nosso tratamento de dados é baseado nas diretrizes legais definidas pela LGPD, principalmente aquelas relativas à execução do contrato de prestação de serviços. Garantimos que qualquer uso de dados pessoais para finalidades não mencionadas será devidamente comunicado ou terá o consentimento solicitado aos titulares dos dados.
                  <br />

                  • Os titulares dos dados sempre terão acesso facilitado às suas informações, podendo ainda solicitar sua edição e, quando permitido por lei, a exclusão de seus dados pessoais.
                  <br />

                  • Reafirmamos que todo tratamento e compartilhamento dos dados fornecidos estão em conformidade com as bases legais da Lei Geral de Proteção de Dados, em especial: Execução de contrato; Consentimento; Legítimo interesse; e Proteção ao crédito.
                </p>

                <h3 className="sub-title text-justify">Disposiçoes gerais</h3>
                <p className="b1 text-justify">
                  • Os USUÁRIOS reconhecem e concordam em aderir e cumprir as orientações e disposições estabelecidas nesta Política de Privacidade da MR TELECOMUNICACOES.
                  <br />
                  • As partes envolvidas confirmam que não há qualquer vínculo empregatício, associativo ou societário entre elas, seus funcionários ou prestadores de serviço, direta ou indiretamente relacionados ao propósito deste documento. Portanto, qualquer acordo, mau uso ou utilização indevida de informações por usuários dentro do site não são de responsabilidade da MR TELECOMUNICACOES.
                </p>

                <h3 className="sub-title text-justify">Converse com a MR TELECOMUNICACOES!</h3>
                <p className="b1 text-justify">
                  • Se precisar de suporte, tiver dúvidas, pedidos ou sugestões sobre esta Política ou outras questões de privacidade, estaremos sempre prontos para atendê-lo. Entre em contato com nossa central de atendimento ou fale diretamente com nosso responsável pela privacidade (encarregado de dados) através do e-mail: recepcao@mrtele.com.br
                </p>

                <Separator />
                <p className="b2 text-center mt_md--20 mt_sm--20">
                  Última atualização: Outubro 2023.
                </p>
                <Separator />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoliticaPrivacidade;
