import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ProdutosOne from "../elements/produtos/ProdutosOne";

const Produtos = () => {
    return (
        <>
            <SEO title="Produtos || MR Telecomunicações" />
            <Layout>
                <div className="main-content">
                    <div className="rwt-produtos-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Produtos disponíveis"
                                            title = "Catálogo de Produtos"
                                            description = "Conheça as melhores soluções em Telecomunicação"
                                        />
                                </div>
                            </div>
                            <ProdutosOne Column="col-lg-4 col-md-6 mt--30 produtos"  />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Produtos;
