import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const TimelineData = [
    {
        id: "1",
        date: "MOTOROLA™ RÁDIO MÓVEL BIDIRECIONAL",
        title: "DEP™ 450",
        description: "Comunique-se de maneira rápida e eficaz. Conecte e coordene toda sua equipe de forma mais eficiente, com os rádios portáteis DEP450 da Motorola.",
        image: "radio-DEP-450",
        workingStep: [
            {
                stepDescription: "Seja em ambientes de construção, fábricas, segurança, fazendas, a cobertura excepcional e bateria duradoura garantem comunicações confiáveis durante todo o dia.",
                stepDescription2: "Não perca tempo, garanta o que há de melhor no mercado."
            },
        ]
    },
    {
        id: "2",
        date: "MOTOROLA™ RÁDIO MÓVEL BIDIRECIONAL",
        title: "DEM™ 300",
        description: "Descubra o DEM 300, com tecnologia digital de ponta este rádio redefine a experiência de comunicação, oferecendo uma qualidade de áudio superior que transforma suas conversas em momentos de total nitidez, mesmo nos ambientes mais desafiadores e ruidosos.",
        image: "radio-DEM-300",
        workingStep: [
            {
                stepDescription: "Supere as barreiras e mantenha-se conectado onde quer que esteja.",
            },
        ]
    },
    {
        id: "3",
        date: " Sinalização",
        title: "Luzes intermitentes & Sirenes",
        description: "Esses elementos são projetados para chamar a atenção e indicar ações eminentes, como uma intervenção policial ou uma resposta médica de emergência.",
        image: "luzes_sirenes",
        workingStep: [
            {
                stepDescription: "Por isso, a especialização em atendimento técnico é nossa prioridade, desempenhando um papel vital. Nossas equipes altamente treinadas compreendem a importância crítica da sinalização, assegurando que cada componente, desde barras intermitentes até estrobos e sirenes, seja instalado com precisão para máxima visibilidade. Cada detalhe é minuciosamente verificado e ajustado, garantindo que, em situações críticas, seu veículo esteja pronto para liderar a resposta. ",
                stepDescription2: "Confie na MR para fornecer soluções que elevam o padrão, mantendo você e sua comunidade protegidos."
            },
        ]
    },
    {
        id: "4",
        date: " MOTOROLA™ SLR5100",
        title: "REPETIDORA - SLR5100",
        description: "O Motorola SLR5100 é um repetidor digital de alto desempenho, projetado para fornecer comunicação clara e confiável. Com sua tecnologia digital MOTOTRBO, esta repetidora oferece qualidade de áudio superior e alcance em comparação com repetidoras analógicas tradicionais.",
        image: "repetidora-SLR5100",
        workingStep: [
            {
                stepDescription: "Além disso, possui recursos adicionais, como a capacidade de conectar até 100 usuários simultaneamente, GPS integrado e monitoramento remoto, tornando-o uma solução universal para empresas nos setores de transporte, logística e manufatura.",
            },
        ]
    },
    {
        id: "5",
        date: " RÁDIO PORTÁTIL BIDIRECIONAL",
        title: "DGP 8550EX",
        description: "O Motorola DGP 8050EX é um rádio comunicador portátil intrinsecamente seguro, projetado para atender às necessidades de comunicação em ambientes de trabalho perigosos e exigentes. Com sua certificação ATEX/INMETRO, este rádio oferece proteção contra explosões em áreas com riscos de gases inflamáveis, poeira ou vapores.",
        image: "radio-DGP-8550EX",
        workingStep: [
            {
                stepDescription: "O rádio permite a comunicação sem fio e instantânea entre dispositivos, além de resistir a condições extremas de poeira, sujeira e água.",
            },
        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) => (
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                            <h3 className="title">
                                                <Link to={`/produtos`} dangerouslySetInnerHTML={{ __html: data.title }}></Link>
                                            </h3>
                                        </ScrollAnimation>

                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                            <p className="description text-justify">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index) => (
                                                <div className="col-lg-12 col-md-12 col-12" key={index}>
                                                    <div className="working-list text-justify">

                                                        <ScrollAnimation
                                                            animateIn="fadeInUp"
                                                            animateOut="fadeInOut"
                                                            animateOnce={true}>
                                                            <p>{data.stepDescription}</p>
                                                            <p>{data.stepDescription2}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="read-morebtn">
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOut="fadeInOut"
                                                animateOnce={true}>
                                                <a className="btn-default btn-border btn-large round" href="/produtos"><span>Saiba Mais</span></a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-90" src={`./images/timeline/${data.image}.png`} alt="Produtos em Destaques" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
